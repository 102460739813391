import { KaeplaBranding } from '@kaepla/types';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, Grid2 as Grid, IconButton, TextField, Typography } from '@mui/material';
import Image from 'mui-image';
import { useState } from 'react';

import { useAuth } from '../../../../AuthReactProvider.js';

interface Options {
  branding: KaeplaBranding;
}

export const BrandedCheckInviteLinkEmail = ({ branding }: Options) => {
  const { signInWithLink, authError, clearAuthError } = useAuth();
  const [email, setEmail] = useState<string>('');

  const loginWithInviteLink = () => {
    void signInWithLink(email);
  };

  return (
    <Grid container spacing={3} alignItems="center">
      {branding?.appLogoUrl && (
        <Grid size={12} container justifyContent="center">
          <Image width={200} alt={branding?.appName} src={branding?.appLogoUrl} />
        </Grid>
      )}
      {branding?.appName && !branding.appLogoUrl && (
        <Grid size={12}>
          <Typography sx={{ color: '#000' }} variant="h2">
            {branding.appName}
          </Typography>
        </Grid>
      )}
      <Grid size={12}>
        <Alert variant="outlined" severity="info">
          Please enter the email address for which you received the invite.
        </Alert>
      </Grid>
      <Grid size={12}>
        <TextField
          label="Email"
          size="small"
          fullWidth
          sx={{ minWidth: 250 }}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          autoFocus
        />
      </Grid>
      {authError && (
        <Grid size={12}>
          <Alert
            variant="outlined"
            severity="error"
            data-testid="login-BrandedLoginErrorAlert"
            action={
              <IconButton
                aria-label="close"
                data-testid="login-BrandedLoginErrorAlertClose"
                color="inherit"
                size="small"
                onClick={() => {
                  setEmail('');
                  clearAuthError();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {authError.code}
          </Alert>
        </Grid>
      )}
      <Grid size={12}>
        <Button
          variant="contained"
          onClick={() => {
            void loginWithInviteLink();
          }}
        >
          Login
        </Button>
      </Grid>
    </Grid>
  );
};
