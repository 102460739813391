import { KaeplaBranding } from '@kaepla/types';
import { Grid2 as Grid, LinearProgress, Stack, Typography } from '@mui/material';
import Image from 'mui-image';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../AuthReactProvider.js';
import { watcherServiceKaepla } from '../../../../firebaseInit.js';
import { documentTitle } from '../../../helpers/documentTitle.js';
import { logger } from '../../../helpers/logger.js';

interface Options {
  branding: KaeplaBranding;
}

export const BrandedLogout = ({ branding }: Options) => {
  const { logOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const doLogout = async () => {
      // logout() from AuthReactProvider unsubscribes all watchers as well
      // but we need to await that here
      const watcherKeys = watcherServiceKaepla.get().getSubscriptionKeys();
      logger.log('watcher keys', watcherKeys);
      watcherServiceKaepla.get().unsubscribeAll();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await logOut(); // router will do the redirect to /Login
    };
    void doLogout();
  }, [logOut, navigate]);

  return (
    <Grid container spacing={3} data-testid="logout-BrandedLogout" alignItems="center">
      {branding?.appLogoUrl && (
        <Grid size={12} container justifyContent="center">
          <Image
            data-testid="logout-BrandedLogoutImage"
            width={200}
            alt={documentTitle(branding)}
            src={branding?.appLogoUrl}
          />
        </Grid>
      )}
      {branding?.appName && !branding.appLogoUrl && (
        <Grid size={12}>
          <Typography data-testid="logout-BrandedLogoutAppName" sx={{ color: '#000' }} variant="h2">
            {branding.appName}
          </Typography>
        </Grid>
      )}
      <Grid size={12}>
        <LinearProgress />
        <Stack
          sx={{ width: '100%', mt: 2 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography data-testid="logout-BrandedLogoutMessage" variant="body2">
            Logging out...
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
