import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { KaeplaProject } from '@kaepla/types';

import { useAuth } from '../../AuthReactProvider';
import { addFirestoreDocumentListener } from '../../services/firestore/addFirestoreDocumentListener';
import { projectState } from '../../services/recoil/nonpersistent/projectState';

export const ProjectListener = () => {
  const { kaeplaUser } = useAuth();
  const { projectId } = useParams();
  const [project, setProject] = useRecoilState(projectState);

  useEffect(() => {
    if (!project?.id && !projectId) return;
    if (!kaeplaUser?.uid) return;

    const fireStorePath = `projects/${project.id ?? projectId}`;
    const unsubscribe = addFirestoreDocumentListener({
      fireStorePath,
      callback: (data) => {
        const _projectUpdate = data as KaeplaProject;
        setProject(_projectUpdate);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [project?.id, projectId, kaeplaUser?.uid, setProject]);

  return null;
};
