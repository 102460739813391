import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import {
  AiLog,
  KaeplaDataOperation,
  KaeplaEventType,
  KaeplaFunctionGroup,
  Run,
  ThreadMessage,
} from '@kaepla/types';
import {
  Alert,
  CircularProgress,
  Grid2 as Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../AuthReactProvider';
import { sendAiMessage } from '../../../services/api/sendAiMessage';
import { addFirestoreCollectionListener } from '../../../services/firestore/addFirestoreCollectionListener';
import { createEvent } from '../../../services/firestore/createEvent';
import { getThreadIDForProjectAndUser } from '../../../services/firestore/getThreadIDForProjectAndUser';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { watcherKeysState } from '../../../services/recoil/nonpersistent/watcherKeysState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { aiLog } from '../../helpers/logger';
import { AdminStats } from '../Admin/AdminStats';
import { CONVERSATIONAL_UI_FONTSIZE_SM, CONVERSATIONAL_UI_FONTSIZE_SX } from '../lib/constants';

import { AIAssistantConversation } from './AIAssistantConversation';
import { AIAssistantScrollWrapper } from './AIAssistantScrollWrapper';
import { AIAssistantUI } from './AIAssistantUI';
import { SendAiMessage } from './SendAiMessage';

export interface ThreadMessageOrdered extends ThreadMessage {
  gap: boolean;
}

interface Options {
  setThreadId: React.Dispatch<React.SetStateAction<string | undefined>>;
  threadId?: string;
}

export const AiAssistant = ({ threadId, setThreadId }: Options) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const { kaeplaUser } = useAuth();

  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);

  const setWatcherKeys = useSetRecoilState(watcherKeysState);
  const [message, setMessage] = useState('');
  const [processing, setProcessing] = useState(true);
  const [initializingThread, setInitializingThread] = useState(true);
  const [aiMessages, setAiMessages] = useState<ThreadMessageOrdered[]>([]);
  const [aiLogs, setAiLogs] = useState<AiLog[]>([]);

  const logInitializeThreadQuestion = useMemo(() => {
    if (!kaeplaUser?.uid) return {};
    return {
      uid: kaeplaUser.uid,
      eventType: KaeplaEventType.AI_ASK_QUESTION,
      functionGroup: KaeplaFunctionGroup.AI,
      operation: KaeplaDataOperation.READ,
      project,
    };
  }, [kaeplaUser?.uid, project]);

  // handle threadId
  useEffect(() => {
    if (!kaeplaUser) return;
    if (!project?.id) return;
    const loadThreadId = async () => {
      if (!initializingThread) {
        setAiMessages([]);
        // TODO: think of a better way
        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
      aiLog.log(`getThreadIDForProjectAndUser: ${kaeplaUser.uid}, ${project.id}`);
      const _threadId = await getThreadIDForProjectAndUser({
        uid: kaeplaUser.uid,
        projectId: project.id,
        type: 'main',
      });
      if (!_threadId) {
        // initialize Thread
        sendAiMessage({
          callBack: (data) => {
            setThreadId(data.threadId);
            aiLog.log(`initialize thread: setThreadId(${data.threadId})`);
            void createEvent(logInitializeThreadQuestion);
            setInitializingThread(false);
          },
          params: {
            scopePathStringified: JSON.stringify(currentScopePath),
            projectId: project.id,
          },
          uid: kaeplaUser.uid,
        });
        return;
      }
      aiLog.log(`reuse thread: setThreadId(${_threadId})`);
      setThreadId(_threadId);
      setInitializingThread(false);
    };
    void loadThreadId();
    return () => {
      setThreadId(undefined);
    };
  }, [
    currentScopePath,
    kaeplaUser,
    logInitializeThreadQuestion,
    project.id,
    initializingThread,
    setThreadId,
  ]);

  // aiRunsListener
  useEffect(() => {
    if (!kaeplaUser) return;
    if (!project?.id) return;
    if (initializingThread) return;
    if (!threadId) {
      setProcessing(false);
      return;
    }
    const fireStorePath = `aiRuns`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
      {
        fieldPath: 'uid',
        opStr: '==',
        value: kaeplaUser.uid,
      },
      {
        fieldPath: 'threadId',
        opStr: '==',
        value: threadId,
      },
      {
        fieldPath: 'data.status',
        opStr: 'in',
        value: ['requires_action', 'in_progress', 'queued'],
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (data) => {
        const _aiRuns = data as Run[];
        aiLog.log(`aiRuns: ${_aiRuns.length}`);
        const load = async () => {
          if (Array.isArray(_aiRuns) && _aiRuns.length > 0) {
            setProcessing(true);
            return;
          } else {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setProcessing(false);
          }
        };
        void load();
      },
      watcherKeyCallback: (key) => {
        setWatcherKeys((_watcherKeys) => {
          const newWatcherKeys = { ..._watcherKeys };
          const keyId = `aiRuns-${project.id}`;
          newWatcherKeys[keyId] = key;
          return newWatcherKeys;
        });
      },
      context: 'AiAssistant',
    });
    return () => {
      unsubscribe();
    };
  }, [initializingThread, kaeplaUser, project.id, setWatcherKeys, threadId]);

  // aiLogListener
  useEffect(() => {
    if (!kaeplaUser) return;
    if (!project?.id) return;
    if (initializingThread) return;
    if (!threadId) return;
    const fireStorePath = `aiLog`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
      {
        fieldPath: 'uid',
        opStr: '==',
        value: kaeplaUser.uid,
      },
      {
        fieldPath: 'threadId',
        opStr: '==',
        value: threadId,
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'loggedAt',
        direction: 'desc',
      },
      limit: 100,
      callback: (data) => {
        const _aiLogsSorted = data as AiLog[];
        const _aiLogs = [..._aiLogsSorted].sort(
          (a, b) => a.loggedAt.toMillis() - b.loggedAt.toMillis(),
        );
        aiLog.log(`aiLogs: ${_aiLogs.length}`);
        setAiLogs(_aiLogs);
      },
      watcherKeyCallback: (key) => {
        setWatcherKeys((_watcherKeys) => {
          const newWatcherKeys = { ..._watcherKeys };
          const keyId = `aiLogs-${project.id}`;
          newWatcherKeys[keyId] = key;
          return newWatcherKeys;
        });
      },
      context: 'AiAssistant',
    });
    return () => {
      unsubscribe();
    };
  }, [initializingThread, kaeplaUser, project.id, setThreadId, setWatcherKeys, threadId]);

  // aiMessagesListener
  useEffect(() => {
    if (!kaeplaUser) return;
    if (!project?.id) return;
    if (initializingThread) return;
    if (!threadId) return;
    const fireStorePath = `aiMessages`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
      {
        fieldPath: 'uid',
        opStr: '==',
        value: kaeplaUser.uid,
      },
      {
        fieldPath: 'threadId',
        opStr: '==',
        value: threadId,
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'createdAt',
        direction: 'desc',
      },
      limit: 100,
      callback: (data) => {
        const _aiMessagesUnsorted = data as ThreadMessageOrdered[];
        const _aiMessages = [..._aiMessagesUnsorted]
          .sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis())
          .map((m, index, array) => {
            const previous = array[index - 1];
            if (previous?.data.role !== m.data.role) {
              return { ...m, gap: true };
            }
            return { ...m, gap: false };
          });
        aiLog.log(`aiMessages: ${_aiMessages.length}`);
        setMessage('');
        setAiMessages(_aiMessages);
        if (_aiMessages.length > 0 && _aiMessages[0].threadId !== threadId) {
          setThreadId(_aiMessages[0].threadId);
        }
      },
      watcherKeyCallback: (key) => {
        setWatcherKeys((_watcherKeys) => {
          const newWatcherKeys = { ..._watcherKeys };
          const keyId = `aiMessages-${project.id}`;
          newWatcherKeys[keyId] = key;
          return newWatcherKeys;
        });
      },
      context: 'AiAssistant',
    });
    return () => {
      unsubscribe();
    };
  }, [initializingThread, kaeplaUser, project.id, setThreadId, setWatcherKeys, threadId]);

  return (
    <Grid container spacing={smUp ? 3 : 0}>
      <Grid size={12}>
        {initializingThread && (
          <Paper sx={{ p: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <CircularProgress size={20} />
              <Alert severity="info">
                <Typography
                  sx={{
                    fontSize: smUp ? CONVERSATIONAL_UI_FONTSIZE_SM : CONVERSATIONAL_UI_FONTSIZE_SX,
                  }}
                >
                  personalizing AI Assistant, hold on...
                </Typography>
              </Alert>
            </Stack>
          </Paper>
        )}
        <AIAssistantUI
          scrollWrapper={
            <AIAssistantScrollWrapper
              message={message}
              aiMessages={aiMessages}
              processing={processing}
              conversation={
                <AIAssistantConversation
                  processing={processing}
                  aiMessages={aiMessages}
                  message={message}
                  aiLogs={aiLogs}
                />
              }
              stats={<AdminStats threadId={threadId} />}
            />
          }
          askQuestion={
            <SendAiMessage
              processing={processing}
              setMessage={setMessage}
              setInitializingThread={setInitializingThread}
            />
          }
        />
      </Grid>
    </Grid>
  );
};
